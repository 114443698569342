document.addEventListener("turbolinks:load", function() {
  var
    backToTopButton = document.getElementById("top-btn");

  function goToTop() {
    // For Chrome, Safari and Opera
    document.body.scrollTop = 0;
    // For IE and Firefox
    document.documentElement.scrollTop = 0;
  }

  function showGoTop() {
    if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
      backToTopButton.classList.add("visible");
    } else {
      backToTopButton.classList.remove("visible");
    }
  }

  window.onscroll = showGoTop;
  backToTopButton.addEventListener("click", goToTop);
});
